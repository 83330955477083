import React, { useEffect, useMemo } from 'react';
import ReactWebChat, { createDirectLine, createStyleSet } from 'botframework-webchat';

import './WebChat.css';
// import logo from './netways_logoO32.png';

const WebChat = ({ className, onFetchToken, store, token }) => {

  //not working
  // const BOT_AVATAR_URL = logo;
  // const USER_AVATAR_URL = logo;

  // const activityMiddleware = () => next => ({ activity }) => {
  //   // Set the avatar URL based on the activity type
  //   const avatarUrl = activity.from.role === 'bot' ? BOT_AVATAR_URL : USER_AVATAR_URL;

  //   // Add the avatar URL to the activity object
  //   activity.avatar = avatarUrl;

  //   return next({ activity });
  // };


  const directLine = useMemo(() => createDirectLine({ token }), [token]);

  const styleSet = useMemo(
    () =>
      createStyleSet({
        backgroundColor: 'Transparent',
        accent:'#FF1744',
        // bubbleBackground:'red',
        bubbleFromUserBackground:'#FF1744',
        bubbleFromUserTextColor:'white',
        // suggestedActionBackgroundColorOnActive:'#FF1744',
        // sendBoxButtonColorOnActive:'#FF1744',
        cardPushButtonBackgroundColor:'#FF1744',
        // // add avatar --not working
        // bubbleFromUserAvatarImage:logo,
        // botAvatarImage:'./netways_logoO32.png',
        // userAvatarImage:logo,
        
        
      }),
    []
  );

  useEffect(() => {
    onFetchToken();
  }, [onFetchToken]);

  return token ? (
    <ReactWebChat className={`${className || ''} web-chat`} directLine={directLine} store={store} styleSet={styleSet} 
    //not working
    // activityMiddleware={activityMiddleware}
    />
    
  ) : (
    <div className={`${className || ''} connect-spinner`}>
      <div className="content">
        <div className="icon">
          <span className="ms-Icon ms-Icon--Robot" />
        </div>
        <p>Please wait while we are connecting.</p>
      </div>
    </div>
  );
};

export default WebChat;
