import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { createStore, createStyleSet } from 'botframework-webchat';

import WebChat from './WebChat';

import './fabric-icons-inline.css';
import './MinimizableWebChat.css';

const MinimizableWebChat = () => {
  const store = useMemo(
    () =>
      createStore({}, ({ dispatch }) => next => action => {
        if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
          dispatch({
            type: 'WEB_CHAT/SEND_EVENT',
            payload: {
              name: 'webchat/join',
              value: {
                language: window.navigator.language
              }
            }
          });
        } else if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
          if (action.payload.activity.from.role === 'bot') {
            setNewMessage(true);
          }
        }

        return next(action);
      }),
    []
  );

  const styleSet = useMemo(
    () =>
      createStyleSet({
        backgroundColor: 'Transparent',
      }),
    []
  );

  const [loaded, setLoaded] = useState(false);
  const [minimized, setMinimized] = useState(true);
  const [newMessage, setNewMessage] = useState(false);
  const [side, setSide] = useState('left');
  const [token, setToken] = useState();

  // To learn about reconnecting to a conversation, see the following documentation:
  // https://docs.microsoft.com/en-us/azure/bot-service/rest-api/bot-framework-rest-direct-line-3-0-reconnect-to-conversation?view=azure-bot-service-4.0

  const handleFetchToken = useCallback(async () => {
    if (!token) {
      // const res = await fetch(process.env.REACT_APP_AZURE_BOT_API_TOKEN);
      const res = await fetch('hhttps://netwaysbotlive.azurewebsites.net/directline/token');

      const { token } = await res.json();
      // const token = 'eyJhbGciOiJSUzI1NiIsImtpZCI6Ii1FMmRMMlhXZ2tCSTNmLV93MTNrWUE0YXBXTSIsIng1dCI6Ii1FMmRMMlhXZ2tCSTNmLV93MTNrWUE0YXBXTSIsInR5cCI6IkpXVCJ9.eyJib3QiOiJOZXR3YXlzQXJhYmlhIiwic2l0ZSI6IkNRQkp3T3dfMFNFIiwiY29udiI6IkVOQVNKWHBxRnZlSWRvVVNzUzRMckgtaW4iLCJuYmYiOjE2ODM0NDAzMDYsImV4cCI6MTY4MzQ0MzkwNiwiaXNzIjoiaHR0cHM6Ly9kaXJlY3RsaW5lLmJvdGZyYW1ld29yay5jb20vIiwiYXVkIjoiaHR0cHM6Ly9kaXJlY3RsaW5lLmJvdGZyYW1ld29yay5jb20vIn0.eOIkw2hctPPEzfofTj0tL0wnkACZs_p6-KDwk6W5HkhevTGPSROF1dHxYNNeiPTIchPpZWm8ffkBttlJjzD7UUMYb3dRT06EmgQ0km_DYkrWibqGxWdAc21MC0IlCQ2MCs5bt-RtRsnI-zTYxkRAYivykl3MGgpT8HycY4NRby_VYxNnNCGYxkBuE5FErFqwmAB7Xab7g3H1OdcLmVDOgd4QjcOOcZREIE7DHht7YcM6F1zvaFGqQhXZTgjygcXhEU9wHDgclR9Ezba-5DBnkT4ShbSgiShUrvYdz3BBpSXLMy5Ncm3L9vcmKvXU-Ybd5KwwrOYeAI-YsQd2RSEREg';

      setToken(token);
    }
  }, [setToken, token]);

  const handleMaximizeButtonClick = useCallback(async () => {
    setLoaded(true);
    setMinimized(false);
    setNewMessage(false);
  }, [setMinimized, setNewMessage]);

  const handleMinimizeButtonClick = useCallback(() => {
    setMinimized(true);
    setNewMessage(false);
  }, [setMinimized, setNewMessage]);

  const handleSwitchButtonClick = useCallback(() => {
    setSide(side === 'left' ? 'right' : 'left');
  }, [setSide, side]);

  // TODO: [P2] Currently, we cannot unmount Web Chat from DOM when it is minimized.
  //       Today, if we unmount it, Web Chat will call disconnect on DirectLineJS object.
  //       When minimized, we still want to maintain that connection while the UI is gone.
  //       This is related to https://github.com/microsoft/BotFramework-WebChat/issues/2750.

  return (
    <div className="minimizable-web-chat">
      {minimized && (
        <button className="maximize" onClick={handleMaximizeButtonClick}>
          <span className={token ? 'ms-Icon ms-Icon--ChatBot' : 'ms-Icon ms-Icon--ChatBot'} />
          {newMessage && <span className="ms-Icon ms-Icon--CircleShapeSolid red-dot" />}
        </button>
      )}
      {loaded && (
        <div className={classNames(side === 'left' ? 'chat-box left' : 'chat-box right', minimized ? 'hide' : '')}>
          <header>
            <div className="filler" />
            <button className="switch" onClick={handleSwitchButtonClick}>
              <span className="ms-Icon ms-Icon--Switch" />
            </button>
            <button className="minimize" onClick={handleMinimizeButtonClick}>
              <span className="ms-Icon ms-Icon--ChromeMinimize" />
            </button>
          </header>
          <WebChat
            className="react-web-chat"
            onFetchToken={handleFetchToken}
            store={store}
            styleSet={styleSet}
            token={token}
          />
        </div>
      )}
    </div>
  );
};

export default MinimizableWebChat;
